/* Remove blue background colour from autofilled inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
}

/* Scale custom step icons so they are not smaller than normal step icons */
.MuiStepLabel-iconContainer > .MuiSvgIcon-root:not(.MuiStepIcon-root) {
  transform: scale(calc(24 / 20));
}

/* PhotoView */
.PhotoView-Slider__Counter {
  visibility: hidden;
}
.PhotoView-Slider__toolbarIcon {
  background-color: rgba(0, 0, 0, 0.813);
}
.PhotoView-Slider__BannerWrap {
  background-color: transparent !important;
}

.error-message {
  color: red;
}